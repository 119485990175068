<template>
  <div class="about">
    <div class="about__content">
      <div class="about__content__left">
        <img src="~assets/images/aboutUs-left.png" class="about__content__left__image">
      </div>
      <div class="about__content__right">
        <div class="about__content__right__title">关于我们</div>
        <div class="about__content__right__info">省省哦是一款集拍摄、招募、交流于一体的集合型APP，为追求美的人提供服务及奔跑向前的动力，欢迎大家！</div>
        <!-- <div class="about__content__right__info">该平台是一个集齐优惠商品及各大CPS的一个平台。里面有优惠花费充值、低价快递、 外卖红包等业务商品</div> -->
        <!-- <div class="about__content__right__btn" @click="handleAppDownload">马上下载体验</div> -->
      </div>
    </div>
    <img src="~assets/images/bg-bottom.png" class="about__bottom">
    <div class="bottom">
      <a class="bottom__text" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{rightText}}</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutUs",
  props:{
    rightText:String
  },
  data(){
    return{
      
    }
  },
  methods: {
    handleAppDownload(){
      window.open('http://www.888iq.cn/download/桃源里.apk');
    }
  }
}
</script>
<style lang="less" scoped>
  .about {
    &__bottom{
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -2;
      height: 280px;
    }
    &__content {
      display: flex;
      margin-bottom: 70px;
      justify-content: space-between;
      &__left {
        width: 880px;
        &__image {
          width: 100%;
          height: 500px;
        }
      }
      &__right {
        width: 600px;
        padding: 30px 0;
        &__title {
          font-weight: bold;
          font-size: 48px;
          padding: 20px 0;
          padding-left: 16px;
        }
        &__info {
          padding-left: 16px;
          line-height: 60px;
          font-size: 30px;
        }
        &__btn {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100px;
          background-color: #BA7200;
          border-radius: 50px;
          color: #fff;
          font-size: 30px;
          font-weight: bold;
          margin-top: 60px;
          cursor: pointer;
        }
      }
    }
    .bottom{
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      &__text{
        color: #F5A772;
        font-size: 14px;
        text-decoration: none;
      }
    }
  }
</style>
