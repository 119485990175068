<template>
  <div class="presentation">
    <div class="presentation__content">
      <div class="presentation__content__left">
        <img :src="inbetweening" class="presentation__content__left__image">
      </div>
      <div class="presentation__content__right">
        <div class="presentation__content__right__title">{{titleValue}}</div>
        <div class="presentation__content__right__info">{{contentValue}}</div>
        <!-- <div class="presentation__content__right__btn" @click="handleAppDownload">马上下载体验</div> -->
      </div>
    </div>
    <ul class="presentation__tabbar">
      <li v-for="(item, i) in tabbars" :key="i" class="presentation__tabbar__item">
        <div class="presentation__tabbar__item__bg" @click="onTabbarItemClick(i)">
          <img :src="imageSelect(i, item)" class="presentation__tabbar__item__bg__image">
        </div>
        <div :class="`presentation__tabbar__item__text ${active == i? 'active': ''}`">{{item.text}}</div>
      </li>
    </ul>
    <div class="bottom">
      <a class="bottom__text" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{rightText}}</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "Presentation",
  props:{
    rightText:String
  },
  data(){
    return{
      tabbars: [
        {
          inbetweening: require('assets/images/photograph_left.png'),
          image: require('assets/images/photograph.png'),
          active: require('assets/images/photograph_active.png'),
          text: '约拍',
          content: '互勉、置换！在这里你想要的约拍它都有'
        },{
          inbetweening: require('assets/images/recruit_left.png'),
          image: require('assets/images/recruit.png'),
          active: require('assets/images/recruit_active.png'),
          text: '招募',
          content: '摄影师、模特！来这里都能找到你心仪的人才'
        },{
          inbetweening: require('assets/images/commodity_left.png'),
          image: require('assets/images/commodity.png'),
          active: require('assets/images/commodity_active.png'),
          text: '商展',
          content: '写真、婚纱照！到这里让你看到拍摄的一切幻想'
        },{
          inbetweening: require('assets/images/works_left.png'),
          image: require('assets/images/works.png'),
          active: require('assets/images/works_active.png'),
          text: '作品展',
          content: '对美的欣赏，灵感的源泉，在这里迈出步伐'
        },{
          inbetweening: require('assets/images/interact_left.png'),
          image: require('assets/images/interact.png'),
          active: require('assets/images/interact_active.png'),
          text: '互动馆',
          content: '有疑问？求真知！专为你打造畅所欲言的环境'
        },{
          inbetweening: require('assets/images/site_left.png'),
          image: require('assets/images/site.png'),
          active: require('assets/images/site_active.png'),
          text: '打卡地',
          content: '发现那些你不知道的好地方，从这里开始出发'
        }
      ],
      active: 0
    }
  },
  computed: {
    imageSelect(i, item){
      return function(i, item){
        return this.active == i? item.active: item.image;
      }
    },
    inbetweening(){
      return this.tabbars[this.active].inbetweening;
    },
    titleValue(){
      return this.tabbars[this.active].text;
    },
    contentValue(){
      return this.tabbars[this.active].content;
    }
  },
  methods: {
    onTabbarItemClick(i){
      this.active = i;
    },

    handleAppDownload(){
      window.open('http://www.888iq.cn/download/桃源里.apk');
    }
  }
}
</script>
<style lang="less" scoped>
  .presentation {
    &__content {
      display: flex;
      margin-bottom: 70px;
      justify-content: space-between;
      &__left {
        width: 880px;
        &__image {
          width: 100%;
          height: 500px;
        }
      }
      &__right {
        width: 600px;
        padding: 30px 0;
        &__title {
          font-weight: bold;
          font-size: 48px;
          padding: 20px 0;
          padding-left: 16px;
        }
        &__info {
          padding-left: 16px;
          line-height: 60px;
          font-size: 30px;
        }
        &__btn {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100px;
          background-color: #BA7200;
          border-radius: 50px;
          color: #fff;
          font-size: 30px;
          font-weight: bold;
          margin-top: 60px;
          cursor: pointer;
        }
      }
    }
    &__tabbar {
      display: flex;
      &__item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__text {
          margin-top: 20px;
          font-size: 18px;
        }
        &__bg {
          cursor: pointer;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background-color: #F3F4F6;
          display: flex;
          justify-content: center;
          align-items: center;
          &__image {
            width: 68px;
            height: 68px;
          }
        }
      }
    }
    .bottom{
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      &__text{
        color: #989898;
        font-size: 14px;
        text-decoration: none;
      }
    }
  }
  .active {
    color: #BA7200;
  }
</style>
