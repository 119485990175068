<template>
  <div class="home">
    <div class="home__header">
      <div class="home__header__left">
        <!-- <img src="~assets/images/logo.png" class="home__header__left__logo"> -->
        <div style="font-size:36px;fong-weight:bold;">省省哦</div>
      </div>
      <div class="home__header__right">
        <div class="home__header__right__tabs">
          <div class="home__header__right__tabs__item" 
            v-for="(item, i) in tabs" :key="i" @click="onTabsItemClick(i)">
            <div :class="active == i? 'active': ''">{{item}}</div>
            <div class="home__header__right__tabs__item__bar" v-show="active == i"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="active == 0">
      <presentation :rightText="rightText"/>
    </div>
    <div v-show="active == 1">
      <about-us :rightText="rightText"/>
    </div>
  </div>
</template>

<script>
import Presentation from "./components/presentation";
import AboutUs from "./components/about-us";
export default {
  name: 'Home',
  components: {
    Presentation,
    AboutUs
  },
  data(){
    return {
      tabs: ['相关业务介绍', '关于我们'],
      active: 0,
      rightText:'',
      copyrights:[
        {host:'localhost',text:'闽ICP备xxxx',title:'本地网站'},
        {host:'888ie.cn',text:'闽ICP备2021014047号-4',title:'省省吧'},
        {host:'888yzs.com',text:'闽ICP备2021014047号-3',title:'省省啦'},
        {host:'888ci.cn',text:'闽ICP备2021014047号-1',title:'厦门言之舍传媒有限公司'},
        {host:'888iq.cn',text:'闽ICP备2021014047号-2',title:'厦门言之舍传媒有限公司'},
        {host:'888ssb.com',text:'闽ICP备2021014047号-6',title:'厦门言之舍传媒有限公司'},
        {host:'ssb888.cn',text:'闽ICP备2021014047号-5',title:'省省嘿'},
        {host:'yzs888.cn',text:'闽ICP备2023012133号-1',title:'赞哄哄科技'},
        ]
    }
  },
  mounted() {
    let href=window.location.origin
    let target=this.copyrights.find(el=>{
      return href.indexOf(el.host)>-1
    })
    this.rightText=target.text
    document.title =target.title
    
  },
  methods: {
    onTabsItemClick(i){
      console.log('执行');
      this.active = i;
    }
  }
}
</script>

<style scoped lang="less">
  .home {
    padding: 0 160px;
    &__header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 120px;
      &__left{
        &__logo{
          width: 100px;
          height: 36px;
        }
      }
      &__right{
        &__tabs{
          display: flex;
          align-items: center;
          &__item{
            padding: 0 30px;
            color: #1A1A1A;
            cursor: pointer;
            font-size: 16px;
            &__bar{
              width: 100%;
              height: 4px;
              background-color: #BA7200;
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
  .active{
    font-weight: bold;
    color: #BA7200;
  }
</style>
